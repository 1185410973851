import React, { useState, useMemo } from 'react';
import Layout from '../organisms/layout';
import Seo from '../organisms/seo';
import { graphql } from 'gatsby';
import withPreview from '../utils/with-preview';
import Breadcrumb from '../organisms/breadcrumb/Breadcrumb';
import MenuStickySinglePage from '../molecules/menu-sticky-single-page/MenuStickySinglePage';
import { getSEOValue } from '../utils/functions';
import BlogDettaglio from '../organisms/blog-dettaglio/BlogDettaglio';
import {
  MapAnchoredModules,
  MapNonAnchoredModules,
} from '../molecules/modules-helpers/ModulesHelpers';
import {
  anchorTypesAboveMenu,
  generateMenuAnchors,
} from '../utils/anchorHelpers';
import useWithCategoryAttributes from '../utils/hooks/useWithCategoryAttributes';

const EMPTY_ARRAY = [];

const PageTemplate = ({
  data: { liferayModuleDetailBlog, allLiferayModuleHighlightTopPage },
  location,
}) => {
  const categoriesList =
    liferayModuleDetailBlog?.liferayFields?.taxonomyCategoryBriefs ??
    EMPTY_ARRAY;
  const [activeModule, setActiveModule] = useState(null);
  const categoriesNames = useMemo(() => {
    return categoriesList.map((category) => category.name);
  }, [categoriesList]);

  const categories = useWithCategoryAttributes(categoriesNames);
  const pageSettings = {
    pageType: 'business', // detail blog pages are always business
    showChangeLanguageButton:
      liferayModuleDetailBlog?.showChangeLanguageButton?.value,
    linkLangMap: liferayModuleDetailBlog?.linkLangMap,
    langCode: liferayModuleDetailBlog.liferayFields?.langCode,
    lateralStickerAnchor: liferayModuleDetailBlog?.lateralStickerAnchor,
    showNavigationMenu: liferayModuleDetailBlog?.showNavigationMenu?.value,
    showBreadcrumb: liferayModuleDetailBlog?.showBreadcrumb?.value,
    showHomePage:
      liferayModuleDetailBlog?.showBreadcrumb?.content?.showHomePage?.value,
    scripts: liferayModuleDetailBlog?.scripts,
  };

  const pageSEOSettings = getSEOValue(liferayModuleDetailBlog.detailPageSeo);

  const buttonCtaLink =
    liferayModuleDetailBlog?.showNavigationMenu?.content?.ctaLink?.value;
  const buttonCtaLabel =
    liferayModuleDetailBlog?.showNavigationMenu?.content?.ctaLabel?.value;

  const showButtonBack =
    liferayModuleDetailBlog?.showNavigationMenu?.content?.showBackButton?.value;
  const labelButtonBack =
    liferayModuleDetailBlog?.showNavigationMenu?.content?.showBackButton
      ?.content?.labelBackButton?.value;

  const anchoredModules =
    liferayModuleDetailBlog?.moduleList?.content?.anchoredModules?.filter(
      (module) => !!module.value
    ) || [];
  const notAnchoredModules =
    liferayModuleDetailBlog?.moduleList?.content?.notAnchoredModules?.filter(
      (module) => !!module.value
    ) || [];

  const menuAnchors = generateMenuAnchors(
    anchoredModules,
    notAnchoredModules,
    anchorTypesAboveMenu.NO_ANCHOR, // no need to generate an anchor above the menu
    ''
  );
  const breadcrumbs = useMemo(() => {
    const homePage = { label: 'Home', link: '/' };
    const breadcrumbsTemp = liferayModuleDetailBlog.breadcrumbs
      .filter((page) => page.link !== '')
      .map((page) => ({
        label: page.pageTitle?.value || page.detailPageTitle?.value,
        link: page.link,
      }))
      .concat({
        label: liferayModuleDetailBlog.detailPageTitle?.value,
      });
    if (pageSettings.showHomePage) {
      breadcrumbsTemp.unshift(homePage);
    }
    return breadcrumbsTemp;
  }, [liferayModuleDetailBlog, pageSettings.showHomePage]);

  const labelPenultimateElement = breadcrumbs.slice(-2, -1)[0]?.label;
  const linkPenultimateElement = breadcrumbs.slice(-2, -1)[0]?.link;

  const imagePostBlog =
    liferayModuleDetailBlog?.blogPost?.content?.imageDetailPost;
  const textPostBlog =
    liferayModuleDetailBlog?.blogPost?.content?.bodyPost?.value;
  const showSocialButton =
    liferayModuleDetailBlog?.blogPost?.content?.bodyPost?.content
      ?.showSocialShare?.value;
  const videoId = liferayModuleDetailBlog?.blogPost?.content?.videoId?.value;
  const videoProvider =
    liferayModuleDetailBlog?.blogPost?.content?.videoId?.content?.videoProvider
      ?.value?.[0];
  const showVideoImage =
    liferayModuleDetailBlog?.blogPost?.content?.videoId?.content?.showVideoImage
      ?.value;

  const bannerTop = liferayModuleDetailBlog?.bannerTop
    ? liferayModuleDetailBlog?.bannerTop?.value
    : allLiferayModuleHighlightTopPage?.nodes?.find(
        (highlight) => highlight?.showOnAllPagesBannerTop?.value === true
      )
    ? allLiferayModuleHighlightTopPage?.nodes?.find(
        (highlight) => highlight?.showOnAllPagesBannerTop?.value === true
      )
    : null;

  return (
    <Layout pageSettings={pageSettings} bannerTop={bannerTop}>
      <Seo
        title={
          pageSEOSettings.ogTitle ||
          liferayModuleDetailBlog.detailPageTitle.value
        }
        description={pageSEOSettings.ogDescription}
        image={pageSEOSettings.ogImage}
        link={pageSEOSettings.ogUrl || liferayModuleDetailBlog.link}
        type={pageSEOSettings.ogType}
        scripts={pageSettings.scripts}
        jsonLd={pageSEOSettings.structuredDataJsonLd}
      />
      <div className='container' data-search-type='blog' {...categories}>
        {!!notAnchoredModules?.length &&
          pageSettings.showBreadcrumb &&
          breadcrumbs.length && <Breadcrumb items={breadcrumbs}></Breadcrumb>}
        <MapNonAnchoredModules
          setActiveModule={setActiveModule}
          nonAnchoredModules={notAnchoredModules}
        />
        {pageSettings.showNavigationMenu && (
          <MenuStickySinglePage
            anchors={menuAnchors}
            activeAnchor={activeModule}
            buttonCtaLink={buttonCtaLink}
            buttonCtaText={buttonCtaLabel}
            showButtonBack={showButtonBack}
            labelButtonBack={labelButtonBack + ' ' + labelPenultimateElement}
            linkButtonBack={linkPenultimateElement}
            hasModulesWithoutAnchorAbove={!!notAnchoredModules.length}
          />
        )}
        {!notAnchoredModules?.length &&
          pageSettings.showBreadcrumb &&
          breadcrumbs.length && <Breadcrumb items={breadcrumbs}></Breadcrumb>}
        <MapAnchoredModules
          anchoredModules={anchoredModules}
          setActiveModule={setActiveModule}
          showNavigationMenu={pageSettings.showNavigationMenu}
        />
        {(imagePostBlog || textPostBlog) && (
          <BlogDettaglio
            title={liferayModuleDetailBlog.detailPageTitle.value}
            text={textPostBlog}
            image={imagePostBlog}
            categories={categoriesList}
            showSocial={showSocialButton}
            videoId={videoId}
            videoProvider={videoProvider}
            showVideoImage={showVideoImage}
            related={liferayModuleDetailBlog?.related}
            linkBack={linkPenultimateElement}
          />
        )}
      </div>
    </Layout>
  );
};

export const query = graphql`
  query ($id: String) {
    liferayModuleDetailBlog(id: { eq: $id }) {
      scripts {
        value
      }
      liferayFields {
        siteId
        articleId
        langCode
        taxonomyCategoryBriefs {
          name
          description
        }
      }
      detailPageTitle {
        value
      }
      breadcrumbs {
        ... on LiferayPageGeneric {
          pageTitle {
            value
          }
          link
        }
        ... on LiferayModuleDetailProduct {
          detailPageTitle {
            value
          }
          link
        }
        ... on LiferayModuleDetailBlog {
          detailPageTitle {
            value
          }
          link
        }
        ... on LiferayModuleDetailIniziative {
          detailPageTitle {
            value
          }
          link
        }
      }
      showChangeLanguageButton {
        value
      }
      showBreadcrumb {
        value
        content {
          showHomePage {
            value
          }
        }
      }
      detailPageSeo {
        value {
          ... on LiferaySubmoduleSeo {
            ogTitle {
              value
            }
            ogType {
              value
            }
            ogDescription {
              value
            }
            ogImageData {
              node {
                publicURL
              }
            }
            ogUrl {
              value
            }
            structuredDataJsonLd {
              value
            }
          }
        }
      }
      lateralStickerAnchor
      link
      linkLangMap
      showNavigationMenu {
        value
        content {
          ctaLabel {
            value
          }
          ctaLink {
            value
          }
          showBackButton {
            value
            content {
              labelBackButton {
                value
              }
            }
          }
        }
      }
      blogPost {
        content {
          bodyPost {
            value
            content {
              showSocialShare {
                value
              }
            }
          }
          imageDetailPost {
            value {
              title
            }
            node {
              gatsbyImageData(width: 792)
            }
          }
          videoId {
            value
            content {
              videoProvider {
                value
              }
              showVideoImage {
                value
              }
            }
          }
        }
      }
      related {
        detailPageTitle {
          value
        }
        blogPost {
          content {
            bodyPost {
              value
            }
            imageDetailPost {
              node {
                gatsbyImageData
              }
            }
          }
        }
        cardDetails {
          content {
            cardTitle {
              value
            }
          }
        }
        liferayFields {
          taxonomyCategoryBriefs {
            description
            name
          }
        }
        link
      }
      bannerTop {
        value {
          ... on LiferayModuleHighlightTopPage {
            ...QueryHighlightTopPage
          }
        }
      }
      moduleList {
        content {
          notAnchoredModules {
            value {
              __typename
              ...QueryAccordion
              ...QueryAgencyLocator
              ...QueryBanner
              ...QueryBannerImmagineCircolare
              ...QueryCardsIconeTestoFooter
              ...QueryFasciaCardEtichettaCircolare
              ...QueryFasciaCardImg
              ...QueryFasciaIcone
              ...QueryFasciaIconeTesto
              ...QueryFasciaInformativaECTA
              ...QueryFasciaProdotto
              ...QueryFastQuote
              ...QueryHero
              ...QueryLateralSticker
              ...QueryPhotogallery
              ...QueryScaricaApp
              ...QueryTestoIntroduttivo
              ...QueryVideo
              ...QueryVideoGallery
              ...QueryVisoreSliderImmagini
              ...QueryTimeline
              ...QueryLinkDocumentList
              ...QueryFasciaTab
              ...QueryFolderTree
              ...QueryFolderTreeAlphabetical
              ...QueryContenutoHtml
              ...QueryAssetList
              ...QueryLandingForm
            }
          }
          anchoredModules {
            value {
              __typename
              ...QueryAccordion
              ...QueryAgencyLocator
              ...QueryBanner
              ...QueryBannerImmagineCircolare
              ...QueryCardsIconeTestoFooter
              ...QueryFasciaCardEtichettaCircolare
              ...QueryFasciaCardImg
              ...QueryFasciaIcone
              ...QueryFasciaIconeTesto
              ...QueryFasciaInformativaECTA
              ...QueryFasciaProdotto
              ...QueryFastQuote
              ...QueryHero
              ...QueryLateralSticker
              ...QueryPhotogallery
              ...QueryScaricaApp
              ...QueryShortlink
              ...QueryTestoIntroduttivo
              ...QueryVideo
              ...QueryVideoGallery
              ...QueryVisoreSliderImmagini
              ...QueryTimeline
              ...QueryLinkDocumentList
              ...QueryFasciaTab
              ...QueryFolderTree
              ...QueryFolderTreeAlphabetical
              ...QueryContenutoHtml
              ...QueryAssetList
              ...QueryLandingForm
            }
          }
        }
      }
    }
    allLiferayModuleHighlightTopPage {
      nodes {
        bannerTop {
          content {
            bannerTopImage {
              node {
                gatsbyImageData(width: 100)
              }
            }
            colorBannerTop {
              value
            }
            ctaLabelBannerTop {
              value
              content {
                ctaColorBannerTop {
                  value
                }
                ctaLinkBannerTop {
                  value
                }
                ctaTypeBannerTop {
                  value
                }
                iconSvgIdCtaBannerTop {
                  value
                  content {
                    iconThicknessCtaBannerTop {
                      value
                    }
                  }
                }
              }
            }
            iconSvgIdBannerTop {
              value
              content {
                iconThicknessBannerTop {
                  value
                }
              }
            }
            tagTitleBannerTop {
              value
            }
            textBannerTop {
              value
            }
            titleBannerTop {
              value
            }
          }
        }
        showOnAllPagesBannerTop {
          value
        }
        htmlTagWrapper {
          value
        }
        liferayFields {
          articleId
          siteId
        }
      }
    }
  }
`;

export default withPreview(PageTemplate, {
  subField: 'liferayModuleDetailBlog',
  fixed: true,
});
