import React from 'react';
import { Wrapper } from '../../utils/components';
import classNames from 'classnames';
import Social from '../../molecules/social-share-button/social';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import './BlogDettaglio.scss';
import ContenutoHtmlInner from '../../molecules/helpers-contenuto-html/ContenutoHtmlInner';
import VideoItem from '../../molecules/video-item/VideoItem';
import FasciaBlog from '../fascia-blog/FasciaBlog';

function BlogDettaglio({
  title,
  text,
  image,
  categories,
  showSocial,
  videoId,
  videoProvider,
  showVideoImage,
  related,
  linkBack,
}) {
  const htmlTagWrapper = 'div';
  const marginTop = 'module-top-gap';
  const backgroundColor = 'transparent';

  const fasciaBlog = {
    backgroundColor: {
      value: ['verde-turchese-10p'],
    },
    products: related?.map((article) => ({ value: article })),
    link: {
      value: linkBack,
      content: {
        linkLabel: {
          value: 'Vedi tutti',
        },
        linkIconThickness: {
          value: ['normal'],
        },
        iconSvgIdLink: {
          value: {
            'icon-type': 'stroked',
            viewbox: '0 0 22 22',
            markup:
              "<g stroke-linecap='round' stroke-linejoin='round'><circle cx='11' cy='11' r='10'></circle><polyline points='11 15 15 11 11 7'></polyline><line x1='7' y1='11' x2='15' y2='11'></line></g>",
            icon: 'arrow-right-circle',
          },
        },
      },
    },
  };

  return (
    <>
      <Wrapper
        data-module-code='C-88'
        data-module-name='blog-dettaglio'
        tag={htmlTagWrapper}
        className={classNames(
          `blog-dettaglio wrapper wrapper--underflow ${marginTop}`,
          {
            'blog-dettaglio--colored-background':
              backgroundColor !== 'transparent',
          }
        )}
        style={{ '--bg': `var(--${backgroundColor})` }}
      >
        {image && !videoId && (
          <GatsbyImage
            image={getImage(image.node.gatsbyImageData)}
            alt={image.value.title || ''}
            className='blog-dettaglio__image'
          ></GatsbyImage>
        )}
        {videoId && (
          <div className='blog-dettaglio__video'>
            <VideoItem
              videoId={videoId}
              videoSite={videoProvider}
              image={
                showVideoImage && image && getImage(image.node.gatsbyImageData)
              }
              imageDescription={showVideoImage && (image.value.title || '')}
            />
          </div>
        )}
        <div className='blog-dettaglio__body'>
          {showSocial && text && <Social />}
          <div className='blog-dettaglio__wrapper'>
            {categories?.length && (
              <ul className='blog-dettaglio__categories'>
                {categories.map((category, index) => (
                  <li
                    key={index}
                    className='blog-dettaglio__category'
                    style={{
                      '--bg-category': `var(${
                        category?.description?.split('=')[1]
                      })`,
                    }}
                  >
                    {category?.name}
                  </li>
                ))}
              </ul>
            )}
            {title && <h1 className='blog-dettaglio__title'>{title}</h1>}
            {text && (
              <section className='box-editorial blog-dettaglio__text'>
                <ContenutoHtmlInner rawContent={text} />
              </section>
            )}
          </div>
        </div>
      </Wrapper>
      {related?.length > 0 ? (
        <FasciaBlog data={fasciaBlog} relatedArticles={true} />
      ) : null}
    </>
  );
}

export default BlogDettaglio;
